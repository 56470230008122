import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChatService } from '@shared/services/api/chat.service';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { WebMessageService } from '@shared/services/api/webmessage.service';
import { ChatMessageModel } from '@shared/models/chatmessage-model';
import { WebmsgModel } from '@shared/models/webmsg-model';
import { FormGroup } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { Observable } from 'rxjs';
import { GenericService } from '../../../@shared/services/api';
import { ComponentService, EventEmitterService } from '../../../@shared/services/common';
import notify from 'devextreme/ui/notify';
import { NgSelectModule } from "@ng-select/ng-select";
import { locale as english } from 'app/auth/data/en'
import { locale as french } from 'app/auth/data/fr';
import { locale as german } from 'app/auth/data/de';
import { locale as turkish } from 'app/auth/data/tr';
import { locale as arabic } from 'app/auth/data/ar';
import { locale as azerbaijani } from 'app/auth/data/az';
import { locale as georgian } from 'app/auth/data/geo';
import { locale as dutch } from 'app/auth/data/nl';
import { locale as italian } from 'app/auth/data/it';
import { locale as russian } from 'app/auth/data/ru';

import { CoreTranslationService } from '../../../@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'chat-application' },
    animations: [
        trigger('toggleBox', [
            state('open', style({
                height: '600px',
                width: '800px'
            })),
            state('closed', style({
                height: '0px',
                width: '0px'
            })),
            state('expand', style({
                height: 'calc(100% - 60px)',
                width: 'calc(100% - 20px)'
            })),
            transition('open => closed', [
                animate('0.3s')
            ]),
            transition('open => expand', [
                animate('0.3s')
            ]),
            transition('closed => open', [
                animate('0.3s')
            ]),
            transition('closed => expand', [
                animate('0.5s')
            ]),
            transition('expand => open', [
                animate('0.3s')
            ]),
            transition('expand => closed', [
                animate('0.5s')
            ]),
        ])
    ]
})
export class ChatComponent implements OnInit {
    public chatGroups;
    form: FormGroup;
    type = "closed";
    toggle(type) {
        if (type == "expand") {
            if (this.type == "expand") {
                this.type = "open"
            } else {
                this.type = "expand"
            }
        }
        else {
            if (this.type == "expand") {
                this.type = "closed"
            } else if (this.type == "open") {
                this.type = "closed"
            } else {
                this.type = "open"
            }
        }
    }
    isChatOpened = false;
    isChatExpanded = false;
    unReadMessage: number;
    chatGroupModel = {
        groupUsers: [],
        groupName: "",
        groupPhoto: ""
    }
    groupDefaultImage: any;
    chatPage = false;
    languageLocal : any = "";
    constructor(
        private _chatService: ChatService,
        private appInfoService: AppInfoService,
        private _webMessageService: WebMessageService,
        private _ngZone: NgZone,
        private _coreConfigService: CoreConfigService,
        private genericService: GenericService,
        public changeDetectorRef: ChangeDetectorRef,
        private element: ElementRef,
        private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService,
        public eventEmitterService: EventEmitterService) {

        let _thisPage = this;
        let promise = this.getBase64Image("/assets/img/rivergroup.png");
        promise.then(function (dataURL) {
            _thisPage.groupDefaultImage = dataURL
        });
        this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian,russian);

        //_chatService.userProfile.status = ''
        //_chatService.userProfile.avatar = '',
        //_chatService.userProfile.settings = { isTwoStepAuthVerificationEnabled: false, isNotificationsOn: false }
 
    }
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;

    isChatLoading = true;
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    getData(): Observable<any> | Promise<any> | any {

        this._chatService.onUserProfileChange.next(this.appInfoService.RiverEntity.currentUser)
        this._chatService.userProfile = this.appInfoService.RiverEntity.currentUser;
        this._chatService.userProfile.status = true;
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this._chatService.getContacts(),
                this._chatService.getChatGroups(),
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    bgClass = null
    isCreateChatGroup: Boolean = false;
    contacts: any[] = [];
    ngOnInit(): void {
        let storedLang = localStorage.getItem('localLang');
        this.getData().then(() => { this.isChatLoading = false; })

        this._chatService.onChatCreateGroupChange.subscribe(val => {
            this.isCreateChatGroup = val;
        })

        this._chatService.onContactsChange.subscribe(res => {
            this.contacts = res;
        });
        
        this._coreConfigService.config.subscribe(val => {
            this.bgClass = val.layout.navbar.backgroundColor
        });
        this._chatService.onChatGroupsChange.subscribe(res => {
            this.chatGroups = res;
            this.unReadMessage = this.chatGroups.reduce((total, user) => total + user.unReadCount, 0);
        });
        this.getChatPanelUserList();
        this.eventEmitterService.invokeReadMessage.subscribe(readCount => {
            this.unReadMessage = this.unReadMessage - readCount.readingMessage;
        })
    }
    updatecourse($event) {
        let array = $event;
        array = array.map(f => f.id).sort((a, b) => a.id - b.id)

        this.chatGroupModel.groupUsers = array.join(',')

    }
    getSignalRMessage() {

        // if (this.appInfo.chatSelectedUserGlobal) {
        let loginUserId = this.appInfoService.currentUser.id;
        //let receiver = this.appInfo.chatSelectedUserGlobal.id;
        this._webMessageService.chatMessageGetAll.subscribe((message: ChatMessageModel) => {
            this._ngZone.run(() => {
                //&& this.module.home
                if (message.groupUserIds.split(",").indexOf(loginUserId.toString()) > -1) {


                    if (message.userId != loginUserId) {
                        // this.playAudio();
                        //  this.notificationNew = true;
                    }
                    //let _thisPage = this;
                    //setTimeout(function () 
                    //    _thisPage.notificationNew = false;
                    //}, 500);

                    if (this.appInfoService.chatBoxMessageOpen && this.appInfoService.chatSelectedUserGlobal && message.receiverId == this.appInfoService.chatSelectedUserGlobal.id)
                        this.messageWithPage(0);

                }
            });
        });
    }
    chatOnlineUsers = [];
    /*getChatUserStatus(): void {

        let _thisPage = this;
        try {
            setInterval(function () {
                console.log("Test 2")
                _thisPage.chatOnlineUsers.forEach(f => {
                    let _d = f.date;
                    let date = *//*this.componentService.cloneModel()*//*_d.setSeconds(f.date.getSeconds());
                    let _dNow = new Date();
                    let dateNow = (_dNow).setSeconds(_dNow.getSeconds() - 7);
                    if (date < dateNow) {
                        let _i = _thisPage.chatOnlineUsers.findIndex(x => x.id == f.id);
                        _thisPage.chatOnlineUsers.splice(_i);
                    }
                });
            }, 2000);
        } catch (err) { }

        try {
            setInterval(function () {
                console.log("Test 2")
                _thisPage._webMessageService.sendChatUserId();
            }, 5000);

        } catch (err) { }


        try {
            this._webMessageService.chatUserStatus.subscribe((s) => {
                let _i = this.chatOnlineUsers.findIndex(x => x.id == s);
                if (_i == -1)
                    this.chatOnlineUsers.push({ id: s, date: new Date() });
                else
                    this.chatOnlineUsers[_i].date = new Date();
            });

        } catch (err) { }

    }*/
    userStatusClass(_groupId, _type) {
        /*_userId*/
        let _fAll = this.chatUserListTemp.find(x => x.id == _groupId && x.type == _type);// ("," + x.UserIds + ",").indexOf("," + _userId + ",")); 
        let _f = _fAll.userIds;
        //let _fArray = _f.split(",");
        if (_type != 2) {
            let fClear1 = "," + _f + ",";
            let fClear2 = "," + this.appInfoService.currentUser.id + ",";
            let _fClear = fClear1.replace(fClear2, ",");
            return this.chatOnlineUsers.findIndex(x => _fClear.indexOf("," + x.id + ",") != -1) == -1 ? " offline " : " online ";
        }
        else {
            return this.chatOnlineUsers.findIndex(x => x.id == _groupId) == -1 ? " offline " : " online ";
        }
    }
    chatUserList: any = [];
    chatUserListTemp: any = [];
    chatUserListImage: any = [];
    photoAll = [];
    getChatPanelUserList() {
        let loginUserId = this.appInfoService.currentUser.id;
        this.genericService.getChatUserList(loginUserId).subscribe((result: any) => {
            this.chatUserList = result;
            this.chatUserListTemp = result;
            this.chatUserListImage = [];
            this.chatUnReadGlobal = 0;
            this.chatUserList.forEach(f => {
                this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                let _image = { id: f.id, type: f.type, photo: f.photo };
                this.chatUserListImage.push(_image);
                //f.photo = "";
            });
            this.photoAll = [];
            this.chatUserListSearch(null);
            let _this = this;
            setTimeout(function () {
                _this.userListColor();
            }, 1000);

        });
    }
    searchColor: any = "#f18567";
    searchTextColor: any = "#3f1c13";
    userListColor() {
        var listItems = document.querySelectorAll('.friends-list-item');
        if (listItems != undefined) {
            listItems.forEach(e => {
                let listItem = <HTMLElement>e;
                listItem.setAttribute('data-color', this.searchColor);
                listItem.setAttribute('style', " --chat-search-color: " + this.searchColor + "; --chat-search-text-color: " + this.searchTextColor);
            })
        }
    }
    chatUserListSearchModel = "";
    chatUserListSearch(event) {
        this.chatUserListTemp = this.chatUserList.filter(o => o.name.toLowerCase().indexOf(this.chatUserListSearchModel.toLowerCase()) > -1);
    }
    chatMessageAll: any = [];
    chatMessageboxImage: any = [];
    chatUnReadGlobal = 0;
    messageWithPage(page): void {
        debugger;
        if (this.appInfoService.chatSelectedUserGlobal.type != this.appInfoService.chatMessageEnum.NotChat) {
            this.genericService.getMessageWithPage(this.appInfoService.currentUser.id, this.appInfoService.chatSelectedUserGlobal.id, page).subscribe((result: any) => {
                this.chatMessageAll = result;
                this.chatMessageAll.forEach(f => {
                    this.chatUnReadGlobal = this.chatUnReadGlobal + f.unReadCount;
                    let _image = { id: f.userId, photo: f.photo };
                    this.chatMessageboxImage.push(_image);
                    f.photo = "";
                });

                this.scrollToBottom();
                this.getChatPanelUserList();
                try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
            });
        }
        else {
            this.chatMessageAll = [];
            this.scrollToBottom();
            try { this.changeDetectorRef.detectChanges(); } catch (ex) { }
        }
    }
    scrollToBottom(): void {
        try {
            let _thisPage = this;
            setTimeout(function () {
                _thisPage.myScrollContainer.nativeElement.scrollTop = _thisPage.myScrollContainer.nativeElement.scrollHeight;
            }, 100);

        } catch (err) { }
    }
    messageSubscribeToEvents() {
        let loginUserId = this.appInfoService.currentUser.id;
        this._webMessageService.messageReceived.subscribe((message: WebmsgModel) => {
            this._ngZone.run(() => {
                console.log("Message received", message)
                if (message.user_id.split(",").indexOf(loginUserId.toString()) > -1/*message.clientuniqueid !== this.uniqueID && message.senduniqueid == this.uniqueID*/) {
                    console.log("Message received", message)
                }

            });
        });

    }

    createChatGroup() {
        if (this.chatGroupModel.groupName != "" && this.chatGroupModel.groupUsers.length > 1) {
            if (this.chatGroupModel.groupPhoto == "") {
                this.chatGroupModel.groupPhoto = this.groupDefaultImage;
            }
            this._chatService.createNewChat(this.chatGroupModel);
            this.chatGroupModel.groupName = "";
            this.chatGroupModel.groupUsers = [];
            this.chatGroupModel.groupPhoto = "";
        } else {
            notify("Grup Adı ve Grup Kullanıcıları Boş Olamaz!", "warning", 3000);
        }
        
    }
    uploadGroupImage(event: any) {
        const file = event.target.files[0];
        const maxSize = 100 * 1024; // 100 KB

        if (file && file.size > maxSize) {
            // Dosya boyutu 100 KB'dan büyük olduğunda hata mesajını görüntüle
            notify("Dosya boyutu 100 KB'dan buyuk olamaz", 'error', 2000)
            return;
        }
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();

            reader.onload = (event: any) => {
                this.chatGroupModel.groupPhoto = event.target.result.split(',').pop();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    getBase64Image(url) {
        let promise = new Promise(function (resolve, reject) {

            var img = new Image();
            // To prevent: "Uncaught SecurityError: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported."
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg|pdf);base64,/, ""));
            };
            img.src = url;
        });

        return promise;
    }

}
