import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


import FileSystemItem from 'devextreme/file_management/file_system_item';
import UploadInfo from 'devextreme/file_management/upload_info';
import { AppInfoService } from '@shared/services/common/app-info.service';

enum Operation {
    Add = 1,
    Remove = 2,
    AddAll = 3,
    RemoveAll = 4
}

enum FileType {
    None = 0,
    Folder = 1,
    File = 2
}

enum UploadType {
    Cancel = 0,
    ChangeName = 1,
    NewVersion = 2
}

@Injectable({
    providedIn: 'root'
})
export class FileManagementService {



    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getItems(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitems", body, this.appInfo.httpOptions);
    }

    getVersions(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getversions", body, this.appInfo.httpOptions);
    }

    renameItems(item: any, name: string): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["name"] = name;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/renameitems", body ,this.appInfo.httpOptions);
    }

    copyItem(item: any, destinationDir: FileSystemItem): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/copyitem", body, this.appInfo.httpOptions);
    }

    moveItem(item: any, destinationDir: FileSystemItem): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/moveitem", body, this.appInfo.httpOptions);
    }

    deleteItem(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/deleteitem", body, this.appInfo.httpOptions);
    }

    createDirectory(parentDir: any, name: string): Observable<any> {
        let bodyObject = {}
        delete parentDir.Permissions
        bodyObject["parentDir"] = parentDir;
        bodyObject["name"] = name
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/createdirectory", body, this.appInfo.httpOptions);
    }

    lastUpload = null;
    uploadFileChunk(chunkMetadata:any,fileData: File, destinationDir: any, uploadType: any, fileObj: any = { OCR: "", newName : null }) {
        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);
        
        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["destinationDir"] = destinationDir
        bodyObject["uploadType"] = uploadType
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileData"] = fileObj
        bodyObject["newName"] = fileObj.newName
        bodyObject["chunkMetadata"] = chunkMetadata
        bodyObject["chunkBlob"] = chunkMetadata.chunkBlob.size



        formData.append('info', JSON.stringify(bodyObject));
        
        const url = this.appInfo.appUrl + "api/filemanagement/uploadfileNewChunky" 
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })

        return this.http.post<any>(url, formData, { headers });
            
    }
    uploadFileOldChunk(chunkMetadata: any, fileData: File, destinationDir: any, uploadType: any, fileObj: any = { OCR: "", newName: null }) {
        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);
        
        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["destinationDir"] = destinationDir
        bodyObject["uploadType"] = uploadType
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileData"] = fileObj
        bodyObject["newName"] = fileObj.newName
        bodyObject["chunkMetadata"] = chunkMetadata
        bodyObject["chunkBlob"] = chunkMetadata?.chunkBlob.size



        formData.append('info', JSON.stringify(bodyObject));

        const url = this.appInfo.appUrl + "api/filemanagement/uploadfilechunky"
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })

        return this.http.post<any>(url, formData, { headers });

    }

    uploadFileToDMS(fileData: File, path: string) {
        debugger;
        const formData: FormData = new FormData();
        formData.append('file', fileData, fileData.name);

        let bodyObject = {}
        bodyObject["path"] = path
        bodyObject["userId"] = this.appInfo.currentUser.id

        formData.append('info', JSON.stringify(bodyObject));

        const url = this.appInfo.appUrl + "api/filemanagement/uploadfiletodmspath"
        let headers = new HttpHeaders({
            'Accept': '*/*',
            'Access-Control-Allow-Origin': url,
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
            'Access-Control-Allow-Credentials': 'true'

        })

        return this.http.post<any>(url, formData, { headers });

    }

    abortFileUpload(fileData: File, chunksInfo: UploadInfo, destinationDir: any) {
        let bodyObject = {}
        delete destinationDir.Permissions
        bodyObject["fileData"] = fileData;
        bodyObject["chunkMetadata"] = chunksInfo
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/abortFileUpload", body, this.appInfo.httpOptions);
    }
    /*
    downloadItem(fileSystemItems: any) {

        let bodyObject = {}
        delete fileSystemItems.Permissions
        bodyObject["fileSystemItems"] = fileSystemItems;
        bodyObject["userId"] = this.appInfo.currentUser.id
        console.log(fileSystemItems)
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadItem", body, this.appInfo.httpOptions)

    }

    downloadVersion(fileId: any) {

        let bodyObject = {}
        bodyObject["fileDataId"] = fileId;
        bodyObject["userId"] = this.appInfo.currentUser.id

        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadVersion", body, this.appInfo.httpOptions)

    }*/

    savePermission(fileItem: any, updateAllChilds = false) {
        
        let bodyObject = {}
        bodyObject["updateAllChilds"] = updateAllChilds;
        bodyObject["permissionJsonString"] = JSON.stringify(fileItem.Permissions)
        bodyObject["userId"] = this.appInfo.currentUser.id
        delete fileItem.Permissions
        bodyObject["fileItem"] = fileItem
       
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/savefilepermission", body, this.appInfo.httpOptions)
    }

    saveSettings(settings: any) {
        
        let bodyObject = {}
        bodyObject["fileManagementSettings"] = settings
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/savefilemanagementsettings", body, this.appInfo.httpOptions)
    }

    getFileManagementSettings() {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getfilemanagementsettings", this.appInfo.httpOptions)
    }

    getUserPermissionsFromFileManagementSettings() {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getUserPermissionsFromFileManagementSettings", body, this.appInfo.httpOptions)
    }

    checkFileNameForNewVersionOrNewFileUpload(fileData: any, destinationDir: any) {
        let bodyObject = {}
        bodyObject["fileName"] = fileData.name
        bodyObject["destinationDir"] = destinationDir
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/checkFileNameForNewVersionOrNewFileUpload", body, this.appInfo.httpOptions)
    }

    searchFile(search) {
        let bodyObject = {}
        bodyObject["search"] = search;
        bodyObject["userId"] = this.appInfo.currentUser.id
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/searchFile", body, this.appInfo.httpOptions)
    }

    downloadFileManagementDataVM(fileId, type) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["type"] = type
        bodyObject["fileDataId"] = fileId
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/downloadFileManagementDataVM", body, this.appInfo.httpOptions)
    }
    downloadFromListFileManagementDataVMG(guid, type) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/downloadFromListFileManagementDataVMG?userId=" + this.appInfo.currentUser.id + "&type=" + type + "&guid=" + guid, { ...this.appInfo.httpOptions, responseType: 'blob' as 'json' }  )
    }

    public saveFileManagementItemVM(fileItem, type) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["type"] = type
        bodyObject["fileItem"] = fileItem
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/saveFileManagementItemVM", body, this.appInfo.httpOptions)
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
   
    addGuid(model:any) {
       
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/addguid", JSON.stringify(model), this.appInfo.httpOptions);

    }


    getExistGuid(id: number) {
        
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getexistguid?id=" + id, this.appInfo.httpOptions);

    }
    findMatchedGuid(guid: string) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/findmatchedguid?guid=" + guid, this.appInfo.httpOptions);

    }
    public getItemsByIdList(fileIds) {
        let params = new HttpParams().set("itemIds", fileIds);

        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitemsbyidlist", JSON.stringify(fileIds), this.appInfo.httpOptions)

    }

    public getItemsByComponent(componentName) {
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getHelpFile?componentName=" + componentName , this.appInfo.httpOptions);

    }

    getFileLogs(item: any): Observable<any> {
        let bodyObject = {}
        delete item.Permissions
        bodyObject["item"] = item;
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getitemslog", body, this.appInfo.httpOptions);
    }
    public recoverFileItem(fileItem) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id
        bodyObject["fileItem"] = fileItem
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/recover", body, this.appInfo.httpOptions)
    }
    public compareFiles(firstId, secondId, type,userId) {
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/compareselectedfiles?firstId=" + firstId + "&secondId=" + secondId + "&type=" + type + "&userId=" + userId, this.appInfo.httpOptions);

    }
    
    public convertFileToBlob(fileSrc: string): Observable<Blob> {
        return this.http.get(fileSrc, { responseType: 'blob' });
    }

    getGalleryItems(): Observable<any> {        
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getgalleryitems", this.appInfo.httpOptions);
    }
    getItemByParentId(id: number, userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getitembyparent?id=" + id + "&userId=" + userId, this.appInfo.httpOptions);
    }
    getItemDatabyId(id: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/filemanagement/getitemdatabyid?id=" + id, this.appInfo.httpOptions);
    }

    getDmsDataPermission(id: number[]) {
        let bodyObject = {}
        bodyObject["userId"] = this.appInfo.currentUser.id;
        bodyObject["id"] = id;
        const body = JSON.stringify(bodyObject)
        return this.http.post<any>(this.appInfo.appUrl + "api/filemanagement/getdmspermission", body,this.appInfo.httpOptions);
    }

}
