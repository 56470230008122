import { Component, ElementRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { TaskService, ReportService, UserService } from '@shared/services/api';
import { PageDesignerService } from '../../../../../@shared/services/api/pagedesigner.service';
import { AppInfoService, ComponentService } from '../../../../../@shared/services/common';
import { ProfileService } from '../../../../pages/profile/profile.service';
import { CoreTranslationService } from '../../../../../@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'app/auth/data/en'
import { locale as french } from 'app/auth/data/fr';
import { locale as german } from 'app/auth/data/de';
import { locale as turkish } from 'app/auth/data/tr';
import { locale as arabic } from 'app/auth/data/ar';
import { locale as azerbaijani } from 'app/auth/data/az';
import { locale as georgian } from 'app/auth/data/geo';
import { locale as dutch } from 'app/auth/data/nl';
import { locale as italian } from 'app/auth/data/it';
import { locale as russian } from 'app/auth/data/ru';


interface FileNode {
    id: number;
    name: string;
    isDirectory: boolean;
    children?: any[];
    icon: string,
    title: string,
    type: string,
    url: string,
    isProcess: boolean;
    isPage: boolean,
    isMenu: boolean,
    isReport: boolean,
    refresh: boolean,
    exactMatch: boolean,
    openInNewTab: boolean

}



@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent {
    private _menuType: string;
    private Date: Date;
    private schemaList: any = [];
    private userGroupIds: string;
    private newprocess: boolean;
    public processNotification: any[] = []
    licenseType: string;
    /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _taskService: TaskService,
        private _reportingService: ReportService,
        private _eventEmitterService: EventEmitterService,
        private _coreMenuService: CoreMenuService,
        private _pageDesignerService: PageDesignerService,
        public componentService: ComponentService,
        private appInfoService: AppInfoService,
        private userService: UserService,
        private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService,
    ) {
        // Set the default menu
        this._menuType = 'vertical-menu';
        this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian, russian);
        this._translateService.onLangChange.subscribe(data => {
            this.createMenuObjects()
        })
        this.licenseType = this.appInfoService.currentUser.licanseType;


        this.createMenuObjects().then(val => {
            if (val) {
            }
        })

    }



    // Accessors
    // -----------------------------------------------------------------------------------------------------



    //Get the menu type
    get menuType(): string {
        return this._menuType;
    }



    @Input()
    //Set the menu type to the native element
    set menuType(value: string) {
        // Remove the old class name from native element
        this._renderer.removeClass(this._elementRef.nativeElement, this.menuType);



        // Store the menuType value
        this._menuType = value;



        // Add the new class name from native element
        this._renderer.addClass(this._elementRef.nativeElement, value);
    }



    createMenuObjects() {
        return new Promise((resolve, reject) => {
            try {
                this.getSchemaList().then(val => {  })
                //this.getMenuList().then(val => { })
                this.getReportList().then(val => { })
                if (this.licenseType == '2') {
                    this.getPageList().then(val => { })
                } else {
                    this.pageCompleted = true;
                    this.isLoadingComplete();
                }
                resolve(true)
            } catch (ex) {
                reject();
            }
        })
    }



    schemaCompleted = false;
    menuCompleted = false;
    reportCompleted = false;
    pageCompleted = false;



    isLoadingComplete() {
        if (this.schemaCompleted && this.reportCompleted && this.pageCompleted) {
            this._eventEmitterService.isMenuLoading(false);
        }
    }


    async getSchemaList() {

        new Promise((resolve, reject) => {
            this._taskService.getSchemeList().subscribe(result => {

                var mainScheme = {
                    id: "river",
                    icon: "git-pull-request",
                    title: this._translateService.instant('forms'),
                    type: 'section',
                    isPage: false,
                    isProcess: false,
                    isMenu: false,
                    isReport: false,
                    refresh: false,
                    children: [],
                    exactMatch: false,
                    openInNewTab: false
                }



                result.forEach((el: any) => {
                    var schemaItem = {
                        id: el.name,
                        icon: "git-pull-request",

                        title: el.name,
                        type: 'collapsible',
                        children: [],
                        isPage: false,
                        isProcess: false,
                        isMenu: false,
                        isReport: false,
                        refresh: false,
                        exactMatch: false,
                        openInNewTab: false,
                        class: el.name.length > 18 ? "long" : ""




                    }



                    el.items.forEach(el2 => {
                        if (!el2.isMenu)
                            schemaItem.children.push({
                                id: el2.id,
                                icon: "circle",
                                title: el2.name,
                                type: "item",
                                url: "river",
                                isPage: false,
                                isProcess: true,
                                isMenu: false,
                                isReport: false,
                                refresh: false,
                                exactMatch: false,
                                openInNewTab: false,
                                class: el2.name.length > 18 ? "long" : ""

                            })
















                    })

                    if (!el.isMenu) mainScheme.children.push(schemaItem)



                })

                this._coreMenuService.PushMenuAndRegister("main", mainScheme);
                this._coreMenuService.setCurrentMenu("main")



                let resultSchema = [];
                resultSchema = result;



                this._eventEmitterService.getAllSchemaList(resultSchema);



                resultSchema.forEach(item => {



                    if (!item.isScheme) {
                        this.filterSchemaList(item.items);
                    }
                    else {
                        this.schemaList.push(item);
                    }
                });

                this.componentService.schemaListAll = this.componentService.cloneModel(this.schemaList);
                this.schemaList = [];
                this.schemaCompleted = true;
                this.isLoadingComplete();
                resolve(true);
            });
        })
    }

    designerMenuList = [];
    async getMenuList() {
        new Promise((resolve, reject) => {
            this._taskService.getSidebarMenu().subscribe(data => {

                var mainScheme = {
                    id: "riverMenu",
                    title: this._translateService.instant('catalog'),
                    type: 'section',
                    icon: 'layers',
                    children: [],
                    isPage: false,
                    isProcess: false,
                    isMenu: false,
                    isReport: false,
                    refresh: false,
                    exactMatch: false,
                    openInNewTab: false
                }
                for (var i = 0; i < data.length; i++) {



                    let _obj = { schemaId: data[i].schemaId, data: JSON.parse(data[i].sidebarJson) };



                    var schemaItem = {
                        id: _obj.schemaId,
                        icon: "layers",
                        title: _obj.data.name,
                        type: 'collapsible',
                        children: [],
                        isPage: false,
                        isProcess: false,
                        isMenu: false,
                        isReport: false,
                        refresh: false,
                        exactMatch: false,
                        openInNewTab: false,
                        class: _obj.data.name.length > 18 ? "long" : ""
                    }



                    _obj.data.items.forEach(el2 => {



                        el2.items.forEach(el3 => {



                            var schemaItem2 = {
                                id: el3.id,

                                icon: "layers",
                                title: el3.name,
                                type: "item",
                                url: 'menu/' + el3.id + "/" + el3.formId + "/" + _obj.schemaId,
                                children: [],
                                isPage: false,
                                isProcess: false,
                                isMenu: true,
                                isReport: false,
                                refresh: true,
                                schemaId: _obj.schemaId,
                                formId: el3.formId,
                                exactMatch: true,
                                openInNewTab: false,
                                class: el3.name.length > 18 ? "long" : ""
                            }



                            schemaItem.children.push(schemaItem2)
                        })




                    })



                    mainScheme.children.push(schemaItem)



                    this.designerMenuList.push(_obj);
                }



                this._coreMenuService.PushMenuAndRegister("main", mainScheme);
                this._coreMenuService.setCurrentMenu("main")



                resolve(true);
            });
        })
    }


    getlangTitle(_name, _languages) {
        
        if (typeof _languages != "undefined" && _languages != null && _languages != "null" && _languages != "" && _languages.length != 0) {
            _languages = JSON.parse(_languages);
            let _currentLang = this._coreTranslationService.countryFlags.find(f => f.Code == this._translateService.currentLang);
            if (_currentLang) {
                let reportLang = _languages[_currentLang.Key];
                if (reportLang)
                    return reportLang;
            }
        }
        return _name;
    }


    async getReportList() {
        new Promise((resolve, reject) => {
            this._reportingService.getReports().subscribe((data: any[]) => {

                

                var mainScheme = {
                    id: 'riverReport',
                    title: this._translateService.instant('riverReports'),
                    type: 'section',
                    icon: 'pie-chart',
                    children: [],
                    isPage: false,
                    isProcess: false,
                    isMenu: false,
                    isReport: false,
                    refresh: false,
                    exactMatch: false,
                    openInNewTab: false
                }



                data.forEach(el => {

                    let _nameSchema = this.getlangTitle(el.name, el.languages);
                    var schemaItem = {
                        id: el.id,
                        icon: "pie-chart",
                        //title: el.name,
                        title: _nameSchema,
                        type: 'collapsible',
                        children: [],
                        isPage: false,
                        isProcess: false,
                        isMenu: false,
                        isReport: false,
                        refresh: false,
                        exactMatch: false,
                        openInNewTab: false,
                        class: _nameSchema.length > 18 ? "long" : ""
                    }



                    el.items.forEach(el2 => {
                        let _name = this.getlangTitle(el2.name, el2.languages);
                        var schemaItem2 = {
                            id: el2.id,
                            icon: "pie-chart",
                            //title: el2.name,
                            title: _name,
                            type: 'item',
                            url: "river3",
                            isPage: false,
                            isProcess: false,
                            isMenu: false,
                            isReport: true,
                            refresh: true,
                            exactMatch: false,
                            openInNewTab: false,
                            class: _name.length > 18 ? "long" : ""
                        }
                        schemaItem.children.push(schemaItem2)
                    })
                    mainScheme.children.push(schemaItem)
                })
                this._coreMenuService.PushMenuAndRegister("main", mainScheme);
                this._coreMenuService.setCurrentMenu("main")
                this.reportCompleted = true;
                this.isLoadingComplete();
                resolve(true);
            });
        })



    }



    async getPageList() {
        new Promise((resolve, reject) => {

            this._pageDesignerService.getPagesByUserId(this.appInfoService.currentUser.id).then((data: any[]) => {


                var mainScheme = {
                    id: 'riverPages',
                    title: this._translateService.instant('riverBoards'),
                    type: 'section',
                    icon: 'trello',
                    children: [],
                    isProcess: false,
                    isMenu: false,
                    isReport: false,
                    refresh: false,
                    exactMatch: false,
                    openInNewTab: false
                }



                var result = this.createFileTree(data, 0)



                result.forEach(el => {
                    mainScheme.children.push(el);
                })

                this._coreMenuService.PushMenuAndRegister("main", mainScheme);
                this._coreMenuService.setCurrentMenu("main")
                this.pageCompleted = true;
                this.isLoadingComplete();
                resolve(true);
            }, reject);
        })



    }




    createFileTree(files: any[], parentId: number): FileNode[] {
        const nodes: FileNode[] = [];



        for (const file of files) {
            if (file.parentId === parentId &&file.type!=3) { //widget tipi 3 onları bu listeye dahil etmiyorum. Tek bir sayfa olarak analytics page üzerinden açıyoruz. İleride değişiklik talep edilirse bu type 3 kontrolünü kaldırmak yeterli olacaktır.
                file.pageType = file.type
                const node: FileNode = file;



                if (file.isDirectory) {
                    file.title = file.name
                    file.children = []
                    file.type = 'collapsible'
                    file.icon = 'folder'
                    file.class = file.name.length > 18 ? "long" : "";
                    const children = this.createFileTree(files, file.id);
                    if (children.length > 0) {
                        node.children = children;
                    }
                } else {
                    file.title = file.name
                    file.children = []
                    file.type = 'item'
                    file.icon = 'trello'
                    file.url = 'test',
                    file.exactMatch = false,
                    file.isPages = true,
                    file.class = file.name.length > 18 ? "long" : "";
                }



                nodes.push(node);
            }
        }



        return nodes;
    }



    filterSchemaList(items) {
        items.forEach(item => {
            if (!item.isScheme) {
                this.filterSchemaList(item.items);
            }
            else {
                this.schemaList.push(item);
            }
        });
    }



}