import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { KanbanModel } from '@shared/models/kanban-model';
import { KanbanColumnModel } from '@shared/models/kanban-column-model';

@Injectable({
    providedIn: 'root'
})
export class KanbanService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getKanban(): Observable<any> {

        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanban", this.appInfo.httpOptions);
    }

    getFilledKanbanForms(): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getfilledkanbanforms", this.appInfo.httpOptions);
    }

    getKanbanColumnsCount(pageId: number, userId: number): Observable<any> {
        let params = new HttpParams().set("pageId", pageId).set("userId", userId) ;
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanbancolumnscount", { params: params });
    }

    getKanbanGroup(pageId: number, userId: number, getCompleted: boolean, ISTake: number = 0, ISSkip: number = 0): Observable<any> {
        let params = new HttpParams().set("pageId", pageId).set("userId", userId).set("getCompleted", getCompleted).set("skip", ISSkip).set("take", ISTake);
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanbangroup", { params: params });
    }

    getKanbanGroupPaging(pageId: number, userId: number, getCompleted: boolean, ISTake: number = 0, ISSkip: number = 0): Observable<any> {
        let params = new HttpParams().set("pageId", pageId).set("userId", userId).set("getCompleted", getCompleted).set("skip", ISSkip).set("take", ISTake);
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanbangrouppaging", { params: params });
    }

    postKanban(model: any, pageId: number): Observable<KanbanModel> {

        return this.http.post<KanbanModel>(this.appInfo.appUrl + "api/kanban/postkanban" + "?pageId=" + pageId, JSON.stringify(model), this.appInfo.httpOptions);
    }

    postKanbanColumn(model: KanbanColumnModel, pageId: number): Observable<KanbanColumnModel> {

        let params = new HttpParams().set("pageId", pageId).set("kanbanvm", JSON.stringify(model));
        return this.http.post<KanbanColumnModel>(this.appInfo.appUrl + "api/kanban/postkanbancolumn" + "?pageId=" + pageId, JSON.stringify(model), this.appInfo.httpOptions);
    }

    getEmployeeData(userId: number): Observable<string> {
        return this.http.post<string>(this.appInfo.appUrl + "api/kanban/postkanbanusermanager?userId=" + userId, this.appInfo.httpOptions);
    }

    getKanbanColumn(pageId: number, columnId: number, userId: number, ISTake: number = 0, ISSkip: number = 0) {
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanbangroupbypageidandcolumnid?pageId=" + pageId + '&columnId=' + columnId + '&userId=' + userId + '&skip=' + ISSkip + '&take=' + ISTake, this.appInfo.httpOptions);
    }


    reorderColumns(ids: string) {
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/reordercolumns?ids=" + ids, this.appInfo.httpOptions);

    }

    moveAndReorderColumnData(id: number, dataIds: string, pageId: number, userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/moveandreordercolumndata?id=" + id + "&dataIds=" + dataIds + "&pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }

    updateDataColumnId(id: number, colId: number, pageId: number, userId: number) {

        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/updatedatacolumnid?id=" + id + "&colId=" + colId + "&pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }


    getKanbanGroupByPageIdAndColumnId(pageId: number, columnId: number, userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/kanban/getkanbangroupbypageidandcolumnid?pageId=" + pageId + "&columnId=" + columnId + "&userId=" + userId, this.appInfo.httpOptions);

    }
    deleteKanbanData(id: number, pageId: number, userId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/deletekanbandata?id=" + id + "&pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }
    completeKanbanData(id: number, userId: number, isCompleted: boolean, pageId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/completekanbandata?id=" + id + "&userId=" + userId + "&isCompleted=" + isCompleted + "&pageId=" + pageId, this.appInfo.httpOptions);
    }
    addFile(id: number, fileId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/addfile?id=" + id + "&fileId=" + fileId, this.appInfo.httpOptions);
    }
    deleteFile(id: number, fileId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/deletefile?id=" + id + "&fileId=" + fileId, this.appInfo.httpOptions);
    }
    updateComment(model: any, pageId: number): Observable<boolean> {
        return this.http.post<boolean>(this.appInfo.appUrl + "api/kanban/updatecomment?pageId=" + pageId, JSON.stringify(model), this.appInfo.httpOptions);
    }
    deleteComment(id: number, commentId: string, pageId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/deletecomment?id=" + id + "&commentId=" + commentId + "&pageId=" + pageId, this.appInfo.httpOptions);
    }
    updateCustomField(model: any): Observable<boolean> {
        return this.http.post<boolean>(this.appInfo.appUrl + "api/kanban/updatecustomfield", JSON.stringify(model), this.appInfo.httpOptions);
    }
    deleteCustomField(id: number, fieldId: string) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/deletecustomfield?id=" + id + "&fieldId=" + fieldId, this.appInfo.httpOptions);
    }
    deleteKanbanColumn(id: number, pageId: number, userId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/deletekanbancolumn?id=" + id + "&pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);

    }
    getKanbanColumnsByPageId(pageId: number, userId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/getselectedboardcolumnsbypageid?pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }
    sendTaskToOtherPage(id: number, columnId: number, pageId: number, userId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/sendtaskotherpage?id=" + id + "&columnId=" + columnId + "&pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }

    getDeletedKanbanTasks(pageId: number, userId: number): Observable<any> {//panodaki silinmi� verileri getirir
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/getdeletedkanbantasks?pageId=" + pageId + "&userId=" + userId, this.appInfo.httpOptions);
    }

    undeleteSelectedTask(id: number, userId: number, pageId: number) { //silinmi� veriyi geri a�ar
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/undeleteselectedtask?pageId=" + pageId + "&userId=" + userId + "&id=" + id, this.appInfo.httpOptions);

    }

    getKanbanStatistics(pageId: number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/kanban/getkanbanstatistics?pageId=" + pageId, this.appInfo.httpOptions);

    }
}
