import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { AppInfoService, EventEmitterService } from '../../../../../@shared/services/common';
import { CoreConfigService } from '../../../../services/config.service';
import { CoreMenuService } from '../../core-menu.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: '[core-menu-vertical-item]',
    templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
    licenseType: string;
    isNotPro: boolean;
    isCollapsed: boolean;
    private _unsubscribeAll: Subject<void>;
    isSemiDark: any;
    layoutType: any;
    constructor(private menuService: CoreMenuService, private appInfo: AppInfoService, public _coreConfigService: CoreConfigService) {
        if (appInfo.currentUser.tabs && appInfo.currentUser.tabs.includes(6)) {
            this.analiticsIsTrue = true;
        } else {
            this.analiticsIsTrue = false;
        }
        this._unsubscribeAll = new Subject();
        this.licenseType = this.appInfo.currentUser.licanseType;
        if (this.licenseType == '1') {
            this.isNotPro = true;
        }
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.isCollapsed = config.layout.menu.collapsed;
            this.isSemiDark = config.layout.skin;
            this.layoutType = config.layout.type;
        });
    }

    @Input()
    item: CoreMenuItem;
    analiticsIsTrue: boolean;
    onMenuItemClick(item: any) {
        this.menuService.onMenuItemClickEvent(item)
    }
}
